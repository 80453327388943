import AppConstants from '@/appConstants';

class FormatFieldHelper {

  formatAmount(amount) {

    // posição do "."
    let i = amount.length;

    while (i > 3) {
      if (amount.includes('.')) {
        if (amount.substring(0, amount.indexOf('.') - 3).length >= 1) {
          amount = amount.substring(0, amount.indexOf('.') - 3) + '.' + amount.substring(amount.indexOf('.') - 3);
          i = amount.indexOf('.');
        }
      } else {
        amount = amount.substring(0, amount.length - 3) + '.' + amount.substring(amount.length - 3);
        i = amount.indexOf('.');
      }
    }

    return amount;
  }

  formatPrice(price) {

    if (price.includes('.')) {
      price = price.replace('.', ',');

      if (price.substring(price.indexOf(',') + 1).length === 1) {
        price = `${price}0`;
      }
    } else {
      price = `${price},00`;
    }
    price = `${this.formatAmount(price.substring(0, price.indexOf(',')))}${price.substring(price.indexOf(','))}`;

    return price;
  }

  formatFieldLenght(field, fieldLength) {
    if (!field) {
      return '';
    }

    if (field.length > fieldLength) {
      field = field.substring(0, fieldLength) + '...';
    }

    return field;
  }

  formatFieldCPF(field) {
    if (field.length === 11) {
      return `${this.formatAmount(field.substring(0, 9))}-${field.substring(9, 11)}`;
    }
    return field;
  }

  formatFieldCNPJ(field) {
    if (field.length === 14) {
      return `${this.formatAmount(field.substring(0, 8))}/${field.substring(8, 12)}-${field.substring(12)}`;
    }
    return field;
  }

  formatFieldPhone(field) {
    // phone field -> DDD + phone
    if (field.length === 11) {
      return `(${field.substring(0, 3)}) ${field.substring(3, 7)}-${field.substring(7)}`;
    }
    return '';
  }

  formatFieldCellPhone(field) {
    // phone field -> DDD + phone
    if (field.length === 11) {
      return this.formatFieldPhone(field);
    }

    if (field.length === 12) {
      return `(${field.substring(0, 3)}) ${field.substring(3, 8)}-${field.substring(8)}`;
    }
    return '';
  }

  removeAccents(originalString) {
    return originalString
      .replace(/[áàãâä]/gi, 'a')
      .replace(/[éè¨ê]/gi, 'e')
      .replace(/[íìïî]/gi, 'i')
      .replace(/[óòöôõ]/gi, 'o')
      .replace(/[úùüû]/gi, 'u')
      .replace(/[ç]/gi, 'c')
      .replace(/[ñ]/gi, 'n')
      .replace(/[^a-zA-Z0-9]/g, ' ');
  }

  unmaskField(field) {
    return field.replace(/\D+/g, '');
  }

  normalizeDuration(duration) {
    return duration.replace(/_/g, '0');
  }

  formatEmpty(value) {
    if (value) {
      return value;
    }
    return '';
  }

  getMasks() {
    return {
      cpf: [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      phone: ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      cellphone: ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    };
  }

  getImportantLabel(importantId) {
    switch (importantId) {
      case AppConstants.IMPORTANT.IMPORTANT.id:
        return AppConstants.IMPORTANT.IMPORTANT.name
      case AppConstants.IMPORTANT.INFO.id:
        return AppConstants.IMPORTANT.INFO.name
      default:
        return AppConstants.IMPORTANT.INFO.name
    }

  }

  getHourformated() {
    const dt = new Date();
    return `${this.addZero(dt.getHours() + 1)}:${this.addZero(dt.getMinutes())}`;
  }

  getDtformated() {
    const dt = new Date();
    return `${dt.getFullYear()}-${this.addZero(dt.getMonth() + 1)}-${this.addZero(dt.getDate())}`;
  }
  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  validateLinkYoutubeVideo(url) {
    const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (url.match(reg)) ? true : false;
  }
  getYoutubeId(url) {
    const reg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (url.match(reg)) ? RegExp.$1 : false;
  }
  getSoundcloudId(url) {
    return url.replace("https://soundcloud.com/", "")
  }
  getSpotifyId(url) {
    if (url.includes('open.spotify.com')) {
      url = url.replace('https://open.spotify.com/episode/', '');
    }

    if (url.includes('?')) {
      url = url.substring(0, url.indexOf('?'));
    }
    return url;

    // https://open.spotify.com/episode/3SsgsVQOuTl9Ttv0CFLKQR
    // https://open.spotify.com/episode/3SsgsVQOuTl9Ttv0CFLKQR?si=uzKvHOymRmCwcEQe5_-Y2A
  }
  getStringBetween(str, start, end) {
    const result = str.match(new RegExp(start + "(.*)" + end));
    if (result) {
      // console.log('getStringBetween: ', result);
      return result[1];
    }
    return '';
  }
  replaceAll(value, searchValue, replaceValue) {
    return value.replace(new RegExp(searchValue, 'g'), replaceValue);
  }

  removeSpecialCharacteres(value) {
    return value.replace(/[`~!@#$%^&*()_|+\-=?;:¨'",.<>{}[]/gi, '');
    // return value.replace(/[^a-zA-Z0-9]/g, '');
    // return value.replace(/[^\w ]/g, '');
    // return value.replace(/[^\w\s]/gi, '');
  }

  limitFieldLength(text, limit) {
    if (text.length > limit) {
      return text.substring(0, limit).concat('...');
    }
    return text;
  }

  convertSecondsToMinutes(time) {
    return Math.floor(time / 60);
  }

  removePricePrefix(val) {
    return val.replace('R$', '').trimStart();
  }
  formatPriceToDecimalString(val) {
    return val.replace('R$', '').trimStart().replace(',', '.');
  }
  parseMonetario(val) {
    // convert string prince in number
    return parseFloat(val.replace("R$", "").replace(/\./g, "").replace(",", ".").trim());
  }
  sumPricesWithQuantity(value) { // receive array of prices and amount { price: '1,00', quantity: 1 }
    const total = value.reduce((acc, val) => acc + (this.parseMonetario(val.price) * val.quantity), 0);
    return total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  }

}

export default new FormatFieldHelper();