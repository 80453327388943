import router from '@/router/index';

export function setRouteQuery(filter, query) {
    const currentRouteQuery = JSON.parse(JSON.stringify(query));
    if (filter) {
        Object.keys(filter).forEach(field => {
            if (filter[field]) {
                currentRouteQuery[field] = filter[field];
            } else delete currentRouteQuery[field];
        });
        router.replace({ query: currentRouteQuery }).catch(() => { });
    }
    return currentRouteQuery;
}
export function buildParamsRouteQuery(query) {
    const params = new URLSearchParams();
    const currentRouteQuery = JSON.parse(JSON.stringify(query));
    Object.keys(currentRouteQuery).forEach(field => {
        if (currentRouteQuery[field]) {
            params.append(field, currentRouteQuery[field]);
        }
    });
    return params;
}