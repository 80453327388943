<template>
  <div class="custom-table">
    <div class="custom-table-buttons">
      <b-button
          class="btn-sm btn-nav-link btn-nav-link-white"
          size="sm"
          variant="flat-primary"
          v-if="hasAddButton"
          @click="$emit('onAdd')"
      >
        <feather-icon icon="PlusIcon" /> 
        Adicionar
      </b-button>
      <b-button
          class="btn-sm btn-nav-link btn-nav-link-white"
          size="sm"
          variant="flat-primary"
          v-if="hasUpdateDataButton"
          @click="updateData"
      >
        <feather-icon icon="RefreshCwIcon" /> 
        Atualizar Dados da Página
      </b-button>
      <b-button
        class="btn-sm btn-nav-link btn-nav-link-white"
        size="sm"
        variant="flat-primary"
        v-if="hasExportDataButton"
        @click="exportData"
      >
        <feather-icon icon="FileTextIcon" />
        Exportar
      </b-button>
      <b-button
        class="btn-sm btn-nav-link btn-nav-link-white"
        size="sm"
        variant="flat-primary"
        v-if="hasAdvancedFilterButton"
        @click.stop="openAdvancedFilter"
      >
        <feather-icon icon="SearchIcon" />
        Filtros
      </b-button>
      
    </div>

    <hr v-if="displayAdvancedFilters">
    <b-row v-if="displayAdvancedFilters">
      <b-col v-for="(item, index) in advancedFilters" :key="index" :md="item.size ? item.size : 3">
        <b-form-group v-if="item.type == 'input'">
          <label :for="item.field">{{item.label}}</label>
          <b-form-input :id="item.field" v-model="item.value" @keyup.enter="fetchAdvancedFilter"/>
        </b-form-group>
        <b-form-group v-if="item.type == 'dropdown'">
          <label :for="item.field">{{item.label}}</label>
          <v-select v-model="item.value" label="name"
            :options="item.filterDropdownItems" :placeholder="item.label" :reduce="option => option.value" @input="fetchAdvancedFilter" :multiple="item.multiple">
            <span slot="no-options">Sem dados</span>
          </v-select>
        </b-form-group>

        <b-form-group v-if="item.type == 'date'">
          <label :for="item.field">{{item.label}}</label>
          <flat-pickr v-model="item.value"
            @on-change="fetchAdvancedFilter"
            :placeholder="item.label" :config="{
              enableTime: false,
              dateFormat: 'Y-m-d',
              altInput: true,
              altFormat: 'd/m/Y',
              allowInput: true,
            }" />
        </b-form-group>
        <b-form-group v-if="item.type == 'dateRange'">
          <label :for="item.field">{{item.label}}</label>
          <flat-pickr v-model="item.value"
            :placeholder="item.label" :config="{
              enableTime: false,
              dateFormat: 'Y-m-d',
              altInput: true,
              altFormat: 'd/m/Y',
              allowInput: true,
              mode: 'range',
            }" />
        </b-form-group>
      </b-col>
      <!-- style="text-align: right;" -->
      <b-col md="12">
        <b-button
          class="btn-sm btn-nav-link btn-nav-link-white"
          size="sm"
          variant="flat-primary"
          @click="fetchAdvancedFilter"
        >
          <feather-icon icon="SearchIcon" />
          Pesquisar
        </b-button>
        <b-button
          class="btn-sm btn-nav-link btn-nav-link-white btn-filter-clear"
          size="sm"
          variant="flat-primary"
          @click="clearAdvancedFilter"
        >
          <feather-icon icon="TrashIcon" />
          Limpar
        </b-button>
      </b-col>
    </b-row>
    <hr v-if="displayAdvancedFilters">

    <div>
      <span class="table-total-result" v-if="totalRows >= 1">{{totalRows}} resultados encontrados</span>
    </div>
    
      <!-- :suppressScrollY="true" -->
      <!-- :suppressScrollX="true" -->
      <!-- :isLoading.sync="isLoading" -->
      <vue-good-table
        mode="remote"
        :columns="fields"
        :rows="items"
        :rtl="false"
        :search-options="{
          enabled: true,
          trigger: 'enter',
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        :max-height="tableMaxHeight"
        class="vue-g-table"
        >
        <!-- :fixed-header="true" -->
        <!-- :fixed-header="true" -->
        <!-- @on-row-click="$refs.menu.open" -->
        
        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <!-- <span @contextmenu.prevent="$refs.menu.open"> -->
          <span>
            <span v-if="props.column.type === 'avatar'">
              <b-avatar :src="props.row[props.column.field]" />
            </span>
            <span v-if="props.column.type === 'image'">
              <img :src="getImageSrc(props.row[props.column.field])" v-if="props.row[props.column.field]" style="max-width: 100px;margin: 5px;"/>
            </span>
            <span v-else-if="props.column.type === 'phone'">
              {{ getPhoneFormat(props.row[props.column.field]) }}
            </span>
            <span v-else-if="props.column.type === 'boolean' && props.column.field === 'isDesktop'">
              {{ props.row.isDesktop ? 'Desktop' : 'Mobile' }}
            </span>
            <span v-else-if="props.column.type === 'boolean'">
              <b-badge
                :variant="getActiveBadge(props.row[props.column.field])"
              >
                {{ getActiveBadgeLabel(props.row[props.column.field]) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.type === 'custom_date'">
              {{ getDateFormat(props.row[props.column.field]) }}
            </span>
            <span v-else-if="props.column.type === 'thumb'">
              <img :src="props.row[props.column.field]" />
            </span>

            <span v-else-if="props.column.type === 'object'">
              {{ getDataObject(props.row[props.column.field], props.column.object_field, props.column.object_field_type, props.column.object_in_object_field) }}
            </span>

            <span v-else-if="props.column.type === 'array'" >
              <span>{{ getDataArray(props.row[props.column.field], props.column.object_field, props.column.object_field_type, props.column.object_in_object_field) }}</span>
              <!-- <span
                v-for="(val, index) in props.row[props.column.field]"
                :key="index"
              >
                {{ getDataObject(val, props.column.object_field, props.column.object_field_type) }},
              </span> -->
            </span>

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'asdasdasdasd'"> -->
            <span v-else-if="props.column.field === 'action_buttons'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="onEdit(props.row)" v-if="hasEditButton">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onDelete(props.row)" v-if="hasDeleteButton">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Deletar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSync(props.row)" v-if="hasSyncButton">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    <span>Sincronizar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onResend(props.row)" v-if="hasResendButton">
                    <feather-icon icon="SendIcon" class="mr-50" />
                    <span>Reenviar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openTemplate(props.row)" v-if="hasOpenTemplateButton">
                    <feather-icon icon="Maximize2Icon" class="mr-50" />
                    <span>Abrir template</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openAnswer(props.row)" v-if="hasAnswer">
                    <feather-icon icon="MessageSquareIcon" class="mr-50" />
                    <span>Responder</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="changeManager(props.row)" v-if="hasChangeManagerButton">
                    <!-- <feather-icon icon="MessageSquareIcon" class="mr-50" /> -->
                    <span>Trocar gestor</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="displayScales(props.row)" v-if="hasDisplayScalesButton">
                    <!-- <feather-icon icon="MessageSquareIcon" class="mr-50" /> -->
                    <span>Ver escalas</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <!-- <span v-else-if="props.column.type == 'limitText'">
              {{ getTextLimit(props.row[props.column.limitText], props.row[props.column.field]) }}
            </span> -->
            <!-- Column: Common -->
            <span v-else >
              {{ props.formattedRow[props.column.field] }}
            </span>

          </span>

          
            <!-- <vue-context ref="menu">
            <li>
              <b-link @click="onEdit(props.row)" v-if="hasEditButton">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Editar</span>
              </b-link>
              <b-link @click="onDelete(props.row)" v-if="hasDeleteButton">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Deletar</span>
              </b-link>
            </li>
          </vue-context> -->

        </template>

        <div slot="emptystate">
          Nenhum registro encontrado
        </div>
      </vue-good-table>

          <!-- <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scroll-custom-table"
      :useBothWheelAxes="true"
    >
      </vue-perfect-scrollbar> -->

      <!-- pagination -->
      <!-- <b-card-body class="d-flex justify-content-between flex-wrap pt-2"> -->
      <b-card-body class="d-flex pt-2">
        <!-- <b-col md="4"></b-col> -->
        
        <!-- page length -->
        <b-col v-if="hasPerPageSelect" class="per-page-table col-2" :class="{'per-page-custom': hasPerPageSelect}">
          <b-form-group
            label="Registro por página"
            label-cols="3"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="currentLimit"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        
        <b-col class="pagination-table col-8" :class="{'pagination-custom': hasPerPageSelect}" style="justify-items: center;">
          <!-- pagination -->
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <!-- <span>Exibindo {{limit}} de {{totalRows}} registros</span> -->
        <!-- <span v-if="totalRows > 1">{{totalRows}} resultados encontrado</span> -->
      </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody,
  BDropdown,
  BDropdownItem,
  //   BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput
  // BLink
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import AppConstants from "@/appConstants";
import formatFieldHelper from '../helpers/format-field-helper';
import convertDatetimeHelper from "../helpers/convert-datetime-helper";
import translateFieldHelper from '../helpers/translate-field-helper';
import messageDialogHelper from '../helpers/message-dialog-helper';
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import VueContext from 'vue-context';

export default {
  components: {
    // VueContext,
    // BLink,
    // VuePerfectScrollbar,
    BCardBody,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    // BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BAvatar,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    BFormInput,
    vSelect,
    flatPickr
  },
  props: {
    fields: {
      type: Array,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => {},
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
    },
    displayLoading: {
      type: Boolean,
      default: false,
    },
    tableMaxHeight: {
      type: String,
      default: AppConstants.CUSTOM_TABLE_DEFAULT_HEIGHT,
    },
    hasEditButton: Boolean,
    hasDeleteButton: Boolean,
    hasSyncButton: Boolean,
    hasResendButton: Boolean,
    hasOpenTemplateButton: Boolean,
    hasAnswer: Boolean,
    hasUpdateDataButton: Boolean,
    hasExportDataButton: Boolean,
    hasAdvancedFilterButton: Boolean,
    hasAddButton: Boolean,
    hasChangeManagerButton: Boolean,
    hasDisplayScalesButton: Boolean,
    advancedFilters: {
      type: Array,
      default: () => {},
    },
    hasPerPageSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentLimit: 20,
      phoneMask: {
        phone: true,
        phoneRegionCode: "BR",
      },
      pageOptions: [],
      currentPage: 1,
      currentRowDetail: null,
      searchTerm: '',
      perfectScrollbarSettings: {
        // maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        swipeEasing: true,
        // useBothWheelAxes: true,
        minScrollbarLength: 10,
      },
      rows: [],
      isLoading: false,
      displayAdvancedFilters: false,
    };
  },
  mounted() {
    this.pageOptions = AppConstants.PAGINATOR_DEFAULT_PAGE_SIZE_OPTIONS;
    // this.currentLimit = this.limit;
  },
  watch: {
    currentLimit(val) {
      this.$emit('limitChanged', val);
    },
  //   limit(newLimit) {
  //   this.$emit('limitChanged', newLimit);
  // }
    // displayLoading(val) {
    //   console.log('[displayLoading]', val)
    //   this.isLoading = val;
    // }
  },
  methods: {
    displayScales(val) {
      console.log("displayScales: ", val);
      this.$emit("displayScales", val);
    },
    changeManager(val) {
      console.log("changeManager: ", val);
      this.$emit("changeManager", val);
    },
    getImageSrc(val) {
      // console.log(val);
      return val?.image_path ? val.image_path : '';
    },
    getTextLimit(limit, text) {
      return formatFieldHelper.limitFieldLength(text, limit);
    },
    onRowClick() {
      this.$refs.menu.open;
    },
    getLabelField(item, rowFields) {
      const index = rowFields.findIndex((r) => r.key == item);
      if (index >= 0) {
        return rowFields[index].label;
      }
      return item;
    },
    getDataArray(rowList, field, fieldType, objectInObjectField) {
      if (!rowList || rowList.length === 0) return '';
      if (field === 'profile') {
        return rowList.map(item => this.getDataObject(item, field, fieldType, objectInObjectField)).join(', ');
      }
      if (field === 'price') {
        return rowList.map(item => `R$ ${this.getDataObject(item, field, fieldType, objectInObjectField)}`).join(' | ');
      }
      return rowList.map(item => this.getDataObject(item, field, fieldType, objectInObjectField)).join(', ');
    },
    getDataObject(data, field, fieldType, objectInObjectField) {
      if (!data) {
        return '';
      }
      if (fieldType) {
        if (fieldType == 'custom_date') {
          return this.getDateFormat(data[field]);
        }
        if (fieldType == 'boolean') {
          return this.getActiveBadgeLabel(data[field]);
        }
      }
      if (objectInObjectField) {
        if (data[field]) {
          return data[field][objectInObjectField];
        }
        return data[field];
      }
      if (field) {
        return data[field];
      }
      if (data.labelPtBr) {
        return data.labelPtBr;
      }
      if (data.namePtBr) {
        return data.namePtBr;
      }
      if (data.name) {
        return data.name;
      }
      return data;
    },
    getPhoneFormat(phone) {
      return phone;
    },
    getDateFormat(dt) {
      return convertDatetimeHelper.convertStringToDateTime(dt);
      // return formatDate(dt, {
      //   dateStyle: "short",
      //   timeStyle: "short",
      // });
    },
    onchangeCheckbox(t) {
      console.log(t);
      t.toggleDetails();
    },
    getActiveBadgeLabel(active) {
      return translateFieldHelper.translateBooleanToLocale(active);
    },
    getActiveBadge(active) {
      if (active) {
        return "light-success";
      }
      return "light-danger";
    },
    handleChangePage(page) {
      console.log("page: ", page);
      this.$emit("changePage", page);
    },
    getObjectKeys(object) {
      return Object.keys(object);
    },
    onRowClicked(row) {
      // console.log(row);
      this.$set(row, "_showDetails", !row._showDetails);
      if (this.currentRowDetail) {
        if (this.currentRowDetail.id != row.id) {
          if (this.currentRowDetail._showDetails) {
            this.$set(
              this.currentRowDetail,
              "_showDetails",
              !this.currentRowDetail._showDetails
            );
          }
        }
      }
      this.currentRowDetail = row;
    },
    onEdit(data) {
      console.log("onEdit: ", data);
      this.$emit("onEdit", data);
    },
    onDelete(data) {
      messageDialogHelper.displayConfirmMessage(
        "Deleção",
        "Deseja deletar o registro?",
        () => {
          console.log("onDelete: " + data);
          this.$emit("onDelete", data);
        }
      );
    },
    onResend(data) {
      console.log("onResend: " + data);
      this.$emit("onResend", data);
    },
    openTemplate(data) {
      console.log("openTemplate: " + data);
      this.$emit("openTemplate", data);
    },
    openAnswer(data) {
      console.log("openAnswer: " + data);
      this.$emit("openAnswer", data);
    },
    onSync(data) {
      console.log("onSync: " + data);
      this.$emit("onSync", data);
    },
    onSortChange(params) {
      console.log(`the user ordered:  ${JSON.stringify(params)}`)
      this.$emit("onSortChange", params[0]);
    },
    onColumnFilter(params) {
      // console.log('onColumnFilter params: ', params);
      this.$emit('onColumnFilter', params.columnFilters)
    },
    updateData() {
      console.log("Updating page data");
      this.$emit("updateData");
    },
    exportData() {
      console.log("Export data");
      this.$emit("exportData");
    },
    openAdvancedFilter() {
      this.displayAdvancedFilters = !this.displayAdvancedFilters;
    },
    fetchAdvancedFilter() {
      const data = {};
      for (let index = 0; index < this.advancedFilters.length; index++) {
        // if (this.advancedFilters[index].value) data[this.advancedFilters[index].field] = this.advancedFilters[index].value;
        data[this.advancedFilters[index].field] = this.advancedFilters[index].value;
      }
      this.$emit("fetchAdvancedFilter", data);
    },
    clearAdvancedFilter() {
      this.$emit("clearAdvancedFilter");
      // for (let index = 0; index < this.advancedFilters.length; index++) {
      //   this.advancedFilters[index].value = '';
      // }
      // this.fetchAdvancedFilter();
    }
  },
};
</script>

<style>
</style>
