// import VueI18n from '@/libs/i18n/index';
// import AppConstants from '@/appConstants';

class TranslateFieldHelper {
    translateBooleanToLocale(value) {
        return value ? 'Sim' : 'Não';
        // return value ? VueI18n.t('BOOLEAN').Yes : VueI18n.t('BOOLEAN').No;
    }
    
}

export default new TranslateFieldHelper();