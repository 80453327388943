class ConvertDateTimeHelper {

  convertEpochToDate(epochDate) {
    // Used to Convert an epoch to human readable date:
    // https://www.epochconverter.com/
    // https://www.epochconverter.com/programming/#javascript
    const date = new Date(epochDate * 1000);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${day}/${month}/${year}`;
  }

  convertEpochToDateTime(epochDate) {

    const date = new Date(epochDate * 1000);
    // console.log('convertEpochToDateTime ==> ',date);

    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    let hour = date.getUTCHours().toString();
    let minute = date.getUTCMinutes().toString();
    let second = date.getUTCSeconds().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    if (date.getUTCHours() < 10) {
      hour = '0' + date.getUTCHours().toString();
    }
    if (date.getUTCMinutes() < 10) {
      minute = '0' + date.getUTCMinutes().toString();
    }
    if (date.getUTCSeconds() < 10) {
      second = '0' + date.getUTCSeconds().toString();
    }

    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }

  convertEpochToDateTimeObject(epochDate) {

    if (!epochDate) {
      return {
        day: '',
        month: '',
        year: '',
        hour: '',
        minute: '',
        second: ''
      };
    }

    const date = new Date(epochDate * 1000);
    // console.log('convertEpochToDateTime ==> ',date);

    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    let hour = date.getUTCHours().toString();
    let minute = date.getUTCMinutes().toString();
    let second = date.getUTCSeconds().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    if (date.getUTCHours() < 10) {
      hour = '0' + date.getUTCHours().toString();
    }
    if (date.getUTCMinutes() < 10) {
      minute = '0' + date.getUTCMinutes().toString();
    }
    if (date.getUTCSeconds() < 10) {
      second = '0' + date.getUTCSeconds().toString();
    }

    return {
      day: day,
      month: month,
      year: year,
      hour: hour,
      minute: minute,
      second: second
    };
  }

  convertEpochToDateObject(epochDate) {
    return new Date(epochDate * 1000);
  }

  convertEpochToDateObjectOffset(epochTime) {
    const dt = new Date(epochTime);
    return new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
  }

  convertEpochToFormattedDate(epochTime, locale) {
    const dt = new Date(epochTime);
    return dt.toLocaleDateString(locale) + ' ' + dt.toLocaleTimeString(locale);
  }

  convertSecondToHour(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    let h = hours.toString();
    let m = minutes.toString();
    let s = seconds.toString();

    if (hours < 10) {
      h = '0' + hours;
    }
    if (minutes < 10) {
      m = '0' + minutes;
    }
    if (seconds < 10) {
      s = '0' + seconds;
    }

    return {
      'hours': h,
      'minutes': m,
      'seconds': s
    };
  }

  convertHourToSecond(hour) {
    // hour needs to be this format -> HH:MM
    const a = hour.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return ((+a[0]) * (60 * 60)) + ((+a[1]) * 60);
  }

  convertDateToEpoch(date) {
    if (!date) {
      return '';
    }

    const myDate = new Date(date);
    return myDate.getTime() / 1000.0;
  }

  convertEpochToDateInter(epochDate) {
    const date = new Date(epochDate * 1000);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}/${month}/${day}`;
  }

  convertDateToStringFormatReport(dt) {
    const date = new Date(dt);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${day}/${month}/${year}`;
  }

  getDateD1RangeFilterFormat() {
    const date = this.getD1();
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}`;
  }

  getDateRangeFilterFormat() {
    const date = new Date();
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}`;
  }

  getDateRangeParamsFormat(dt) {
    const date = new Date(dt);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}`;
  }

  convertDateToStringFormat(dt) {
    const date = new Date(dt);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${day}${month}${year}`;
  }

  convertDateToStringFormatFrom(dt) {
    const date = new Date(dt);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}T00:00:00`;
  }

  convertDateToStringFormatTo(dt) {
    const date = new Date(dt);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}T23:59:59`;
  }

  convertStringToDateTime(dt) {

    const date = new Date(dt);

    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    let hour = date.getUTCHours().toString();
    let minute = date.getUTCMinutes().toString();
    let second = date.getUTCSeconds().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    if (date.getUTCHours() < 10) {
      hour = '0' + date.getUTCHours().toString();
    }
    if (date.getUTCMinutes() < 10) {
      minute = '0' + date.getUTCMinutes().toString();
    }
    if (date.getUTCSeconds() < 10) {
      second = '0' + date.getUTCSeconds().toString();
    }

    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  }

  convertStringToDateTimeSiga(dt) {

    const date = new Date(dt);

    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    let hour = date.getUTCHours().toString();
    let minute = date.getUTCMinutes().toString();
    let second = date.getUTCSeconds().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    if (date.getUTCHours() < 10) {
      hour = '0' + date.getUTCHours().toString();
    }
    if (date.getUTCMinutes() < 10) {
      minute = '0' + date.getUTCMinutes().toString();
    }
    if (date.getUTCSeconds() < 10) {
      second = '0' + date.getUTCSeconds().toString();
    }

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }


  normalizeDateStringToUTCString(field) {
    // receive field like this => 17-02-2021 12:00
    const split = field.split(' ');
    const dt = new Date(split[0]);
    dt.setHours(Number(split[1].split(':')[0]));
    dt.setMinutes(Number(split[1].split(':')[1]));
    return dt.toUTCString();
  }

  getD1() {
    const dt = new Date();
    dt.setDate(dt.getDate() + 1);
    return dt;
  }

  getFirstDayOfMonth() {
    const dt = new Date();
    dt.setDate(1);
    return dt;
  }
  getFirstDayOfCurrentYear() {
    const dt = new Date();
    dt.setDate(1);
    dt.setMonth(0);
    return dt;
  }
  getLastDayOfCurrentYear() {
    const dt = new Date();
    dt.setMonth(12);
    dt.setDate(-1);
    dt.setHours(23);
    dt.setMinutes(59);
    dt.setSeconds(0);
    return dt;
  }

  convertDateParamsTimeFormat(value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) {
    if (!value) return value
    let dtString = new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
    return dtString.replace(',', '');
  }
  convertDateParams(value) {
    const date = new Date(value);
    let day = date.getUTCDate().toString();
    let month = (date.getUTCMonth() + 1).toString();
    const year = date.getFullYear().toString();

    if (date.getUTCDate() < 10) {
      day = '0' + date.getUTCDate();
    }
    if ((date.getUTCMonth() + 1) < 10) {
      month = '0' + (date.getUTCMonth() + 1);
    }

    return `${year}-${month}-${day}`;
  }
  getDateWithoutTimezone(val) {
    let dtIso = new Date();
    if (val) {
      dtIso = new Date(val);
    }
    let dt = new Date(dtIso.toISOString().slice(0, -1));
    return dt;
  }
  getDateWithLastHour(val) {
    const dt = this.getDateWithoutTimezone(val);
    dt.setHours(23);
    dt.setMinutes(59);
    dt.setSeconds(0);
    return dt;
  }
  getDaysWithoutWeekendDays(dt) {
    let endDate = new Date(dt);
    const daysArray = this.getDaysArray(endDate);
    const daysToAdd = daysArray.filter(day => {
      const dayOfWeek = day.dayOfWeek;

      return dayOfWeek === 0 || dayOfWeek === 6;
    }).length;
    endDate.setDate(endDate.getDate() + daysToAdd);
    return endDate;
  }
  getDaysArray(endDate) {
    const daysArray = [];
    const currentDate = new Date();
    while (currentDate <= endDate) {
      daysArray.push({ date: new Date(currentDate), dayOfWeek: currentDate.getDay() });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysArray;
  }

  getNextDateWithoutWeekend(dateToCheck) {
    const dtCheck = new Date(dateToCheck);
    while (this.isDateWeekend(dtCheck)) {
      dtCheck.setDate(dtCheck.getDate() + 1);
    }
    return dtCheck;
  }

  isDateWeekend(dt) {
    return dt.getDay() == 0 || dt.getDay() == 6;
  }


}

export default new ConvertDateTimeHelper();
