<template>
  <div id="clienteSetores">

    <form
        @submit="onSubmit"
        @reset="onReset"
        v-if="show">
      <div class="form-row">

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Local</label>
          <b-form-select
              id="input-1"
              v-model="form.id_unidade"
              :options="locais"
              required
          ></b-form-select>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Nome Setor</label>
          <b-form-input
              id="input-2"
              v-model="form.nome"
              required
          ></b-form-input>
        </div>

        <!-- <b-button class="col-md-1 mb-3" type="submit" :variant="corBtn">{{statusBtn}}</b-button> -->
        <div class="form-group col-lg-3" v-if="insertDB">
          <b-button class="col-md-5 m-1" type="submit" variant="success">Salvar</b-button>
        </div>

        <div class="form-group col-lg-3" v-else>
          <b-button class="col-md-5 m-1" type="submit" variant="warning">Atualizar</b-button>
          <b-button class="col-md-5 m-1" type="submit" variant="danger" @click="insertDB=true; onReset(); getSetores()">Cancelar</b-button>
        </div>


      </div>
    </form>

<!--        <b-card-->
<!--            class="mt-3"-->
<!--            header="Resultado do Form">-->
<!--          <pre class="m-0">{{ form }}</pre>-->
<!--        </b-card>-->


    <div>
        <b-modal ref="my-modal" hide-footer title="">
            <div class="d-block text-center">
                <h3>Você confirma a exclusão?</h3>
            </div>
            <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
            <b-button class="mt-2" variant="outline-danger" block @click="hideModal">Não</b-button>
        </b-modal>
    </div>



    <div class="linhaVermelha"></div>

    <div class="marginTopo">
      <b-table striped responsive hover :fields="fields" :items="items">
        <template v-slot:cell(actions)="data">
          <b-button variant="outline-warning btn-sm" class="mr-2" @click="editar(data.item)">Editar</b-button>
          <b-button variant="outline-danger btn-sm" class="mr-2" @click="showModal(data.item)">Apagar</b-button>
        </template>
      </b-table>
    </div>

  </div>
</template>


<script>


import api from "../../services/api";

export default {
  name: "ClienteSetores",
  data() {
    return {
      
      insertDB: true,


      items: [],

      fields: [
        {key: 'nomeLocal', label: 'Nome do local'},
        {key: 'nome', label: 'Setor'},
        {key: 'actions', label: 'Ações'},
      ],

      locais: [],


      form: {
        id_unidade: "",
        nome: "",
      },
      show: true
    }

  },

  // created TRAZER AS INFORMAÇÃO PARA O SELECT LOCAL
  created() {


    const idCliente = localStorage.getItem('idCliente');
    console.log('idcliente', idCliente)
    api.get(`webadmin/clientes/unidades/${idCliente}`)
        .then(res => {

          //MONTAR A LISTA DE SETORES DO SELECT
          const novaLista = res.data.map(items => {
            const unidade = {
              value: items.id,
              text: items.nome,
            }
            return unidade;
          })

          // console.log('UnidadesLocal',novaLista);
          this.locais = novaLista;
        })
        .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'))

    this.getSetores()
  },


  methods: {

    editar(item) {
      // console.log('Iemmmmm',item)
      this.form = item
      this.insertDB = false
    },

    apagar() {
        this.$refs['my-modal'].hide()

        api.delete(`webadmin/clientes/setor/${this.form.id}`)
        .then(() => {

            this.msg('SETOR', 'REMOVIDO COM SUCESSO.', 'success')
            this.onReset()
            this.getSetores()
        })
        .catch(err => this.msg('ERRO AO REMOVER SETOR.', err.response.data, 'danger'))
    },

    showModal(data) {
        this.form.id = data.id
        // console.log(data.id)
        this.$refs['my-modal'].show()
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },

    onSubmit(evt) {
      evt.preventDefault();

      if(this.form.id) {
        api.put('webadmin/cliente/setores', this.form)
            .then(() => {
              this.msg('SETORES', 'ATUALIZADO COM SUCESSO.', 'success')
              this.onReset()
              this.getSetores()
              delete this.form.id
              this.insertDB = true
            })
            .catch(err => this.msg('ERRO AO ATUALIZAR SETORES', err.response.data, 'danger'))
      } else {

        this.form.idCliente = localStorage.getItem('idCliente');

        api.post('webadmin/cliente/setores', this.form)
            .then(() => {
              this.msg('SETORES', 'CADASTRADO COM SUCESSO.', 'success')
              this.onReset()
              this.getSetores()

              this.$store.commit('setAcordeonRestantes', true)
            })
            .catch(err => this.msg('ERRO AO CADASTRAR SETORES', err.response.data, 'danger'))
      }
    },

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

    getSetores() {
      const idCliente = localStorage.getItem('idCliente');
      api.get(`webadmin/clientes/setores/${idCliente}`)
          .then(res => {
            this.items = res.data

          })
          .catch(err => this.msg('Erro: Ao buscar Setores.', err, 'danger'))
    },

    teste() {
      console.log('teste');
    },

    onReset() {

      // Redefina os valores de formulário
      this.form.id_unidade = null;
      this.form.nome = null;
      this.form.options = null;
      // Limpar o estado de validação do formulário do navegador nativo
      // this.show = false;
    }
  },

};


</script>

<style scoped>
#ClienteSetores {
  position: inherit;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, .125);

  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.linhaVermelha {
  border-top-style: groove;
}

.marginTopo {
  margin-top: 25px;
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: none;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
</style>