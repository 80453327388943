import Vue from 'vue';
// import VueI18n from '@/libs/i18n/index';

// const timer = 3000;

class MessageDialogHelper {

  displayCreateSuccessMessage() {
    this.displaySuccessMessage('criado');
  }
  displayUpdateSuccessMessage() {
    this.displaySuccessMessage('atualizado');
  }
  displayDeleteSuccessMessage() {
    this.displaySuccessMessage('deletado');
  }

  displaySuccessMessage(type) {
    Vue.prototype.$swal({
      text: `Dados ${type} com sucesso!`,
      icon: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: true,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    });
  }

  displaySuccessMess(text) {
    Vue.prototype.$swal({
      text: text,
      icon: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: true,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    });
  }

  displayDeleteDataMessage(callback) {
    Vue.prototype.$swal({
      title: 'Deletar',
      text: 'Você tem certeza em deletar?',
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: true,
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        this.displayDeleteSuccessMessage();
        callback();
      }
    });
  }

  displayWithInputTextSubmit(title, callback) {
    Vue.prototype.$swal({
      title: title,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true,
      preConfirm: (inputText) => {console.log(`dialog with input text val: ${inputText}`)},
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.value);
      }
    })
    
    // Vue.prototype.$swal({
    //   title: 'Deletar',
    //   text: 'Você tem certeza em deletar?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   allowOutsideClick: true,
    //   confirmButtonText: 'Sim, tenho certeza!',
    //   cancelButtonText: 'Cancelar',
    //   customClass: {
    //     confirmButton: 'btn btn-primary',
    //     cancelButton: 'btn btn-outline-danger ml-1',
    //   },
    //   buttonsStyling: false,
    // }).then(result => {
    //   if (result.value) {
    //     this.displayDeleteSuccessMessage();
    //     callback();
    //   }
    // });
  }

  displayConfirmMessage(title, text, callback) {
    Vue.prototype.$swal({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: true,
      confirmButtonText: 'Sim, tenho certeza!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  }

  displayConfirmMessageWithResponseVal(title, text, callback) {
    Vue.prototype.$swal({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: true,
      confirmButtonText: 'Sim, tenho certeza!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      callback(result.value);
    });
  }

  displayDefaultMessage(title, text) {
    Vue.prototype.$swal({
      title: title,
      text: text,
      showCancelButton: false,
      allowOutsideClick: true,
      confirmButtonText: 'Sim, tenho certeza!',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    });
  }

}

export default new MessageDialogHelper();
