<template>
  <div id="Solicitacoes" class="contorno">
    <b-overlay variant="white" :show="tableLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card>
        <b-tabs>
          <b-tab title="Solicitações">
            <custom-table v-if="displayTable" ref="custom-table" :displayLoading="tableLoading" :fields="fields"
              :items="items" :totalRows="totalRows" :limit="limit" :hasChangeManagerButton="true" 
              :hasDisplayScalesButton="true" :hasUpdateDataButton="true"
              :hasAdvancedFilterButton="true" :hasPerPageSelect="true"
              :advancedFilters="advancedFilters" @changePage="onChangePage" @onSortChange="onSortChange"
              @onColumnFilter="onFilter" @updateData="loadData" @fetchAdvancedFilter="onFilter"
              @limitChanged="onLimitChanged" @clearAdvancedFilter="onClearAdvancedFilter"
              @displayScales="toEscala" @changeManage="changeManage"/>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import api from "../../services/api"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'
import { BCard, BOverlay } from "bootstrap-vue";
import { setRouteQuery, buildParamsRouteQuery } from "@/helpers/route-query-helper";
import CustomTable from "../../components/CustomTable.vue";
// import AppConstants from '@/appConstants';

dayjs.locale('pt-br')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("America/Bahia")

export default {
  props: {
    urlQuery: Object
  },
  name: "Solicitacoes",
  components: {
    BCard,
    BOverlay,
    CustomTable,
  },
  data() {
    return {
      displayTable: false,
      tableLoading: false,
      totalRows: 0,
      sortField: {},
      currentFilter: {},
      limit: 20,
      page: 1,
      
      fields: [
        { field: "action_buttons", label: "Ações", sortable: false },
        { field: "dataEvento", label: "Data", sortable: true },
        { field: "cliente", label: "Cliente", sortable: true },
        { field: "unidade", label: "Unidade", sortable: true },
        { field: "nomeEvento", label: "Nome evento", sortable: true },
        { field: "setor", label: "Setor", sortable: true },
        { field: "funcao", label: "Função", sortable: true },
        { field: "solicitante", label: "Solicitante", sortable: true },
        { field: "qtdCliente", label: "Qtd", sortable: true },
        { field: "cortesia", label: "Cortesia", sortable: true },
        { field: "confirmado", label: "Confirmado", sortable: true },
        { field: "countCheckIn", label: "Check-in", sortable: true },
        { field: "entrada", label: "Entrada", sortable: true },
        { field: "saida", label: "Saida", sortable: true },
        { field: "status", label: "Status", sortable: true },
        { field: "gestor", label: "Gestor", sortable: true },
        { field: "id", label: "id", sortable: true },
      ],
      advancedFilters: [
        { field: "cliente", label: "Cliente", type: 'input', value: '', size: 2 },
        { field: "unidade", label: "Unidade", type: 'input', value: '', size: 2 },
        { field: "setor", label: "Setor", type: 'input', value: '', size: 2 },
        { field: "gestor", label: "Gestor", type: 'input', value: '', size: 2 },
        { field: "dtRange", label: "Data", type: 'dateRange', value: '', size: 2 },
        {
          field: "status", label: "Status", type: 'dropdown', value: '', size: 2, multiple: false, filterDropdownItems: [
            { name: 'todos', value: 'todos' },
            { name: 'solicitado', value: 'solicitado' },
            { name: 'em processo', value: 'em processo' },
            { name: 'alterado', value: 'alterado' },
            { name: 'escala completa', value: 'escala completa' },
            { name: 'em andamento', value: 'em andamento' },
            { name: 'em aprovação', value: 'em aprovação' },
            { name: 'em contestação', value: 'em contestação' },
            { name: 'em reavaliação', value: 'em reavaliação' },
          ]
        },
      ],
      
      buscaCliente: '',
      buscaUnidade: '',
      buscaSetor: '',
      buscaGestor: '',
      buscaStatus: '',
      buscaDataMin: '',
      buscaDataMax: '',
      statusList: ['todos','solicitado','em processo','alterado','escala completa',
                  'em andamento','em aprovação','em contestação','em reavaliação'],
       
      status: 'solicitado',

      items: [],


    };
  },

  methods: {
    onClearAdvancedFilter() {
      for (let index = 0; index < this.advancedFilters.length; index++) {
        this.advancedFilters[index].value = '';
      }
      this.loadData();
    },
    onLimitChanged(newLimit) {
      this.limit = newLimit;
      this.loadData();
    },
    setInitialTableFilters() {
      Object.keys(this.urlQuery).forEach(item => {
        this.advancedFilters[this.advancedFilters.findIndex(val => val.field == item)].value = this.urlQuery[item];
      });
      this.loadData();
    },
    onChangePage(page) {
      this.page = page;
      this.loadData();
    },
    onFilter(data) {
      console.log('onFilter', data);

      this.currentFilter = { ...data };
      setRouteQuery(this.currentFilter, this.$route.query);
      this.loadData();
    },
    onSortChange(params) {
      this.sortField = params;
      this.loadData();
    },
    clearFilters() {
      this.fields.forEach(field => {
        if (field.filterOptions && field.filterOptions.filterValue !== undefined) {
          field.filterOptions.filterValue = '';
        }
      });
      const query = { ...this.$route.query };
      Object.keys(query).forEach(key => {
        if (!['page', 'limit', 'lang'].includes(key)) {
          delete query[key];
        }
      });
      this.$router.replace({ query });
      this.$forceUpdate();
    },

    loadData() {
      this.tableLoading = true;
      this.items = []
      this.load = true

      // const camposParaFiltro = [{ campo: 'nenhum', valor: 'nenhum' }]
      // if (this.buscaCliente.length > 0) camposParaFiltro.push({ campo: 'cliente', valor: this.buscaCliente })  
      // if (this.buscaUnidade.length > 0) camposParaFiltro.push({ campo: 'unidade', valor: this.buscaUnidade })  
      // if (this.buscaSetor.length > 0) camposParaFiltro.push({ campo: 'setor', valor: this.buscaSetor }) 
      // if (this.buscaGestor.length > 0) camposParaFiltro.push({ campo: 'gestor', valor: this.buscaGestor })  
      // const body = {
      //   campos: camposParaFiltro,
      //   status: this.status,
      //   dataIni: this.buscaDataMin,
      //   dataFim: this.buscaDataMax
      // }
      
      const params = this.searchParams;
      // .post(`webadmin/solicitacoes/${camposParaFiltro}/${this.status}/${this.buscaDataMin}/${this.buscaDataMax}`)
      
      console.log('loaddata')
      api
        .get('webadmin/solicitacoes', { params })
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
          this.displayTable = true;
          this.tableLoading = false;
        })
        .catch(err => {
          this.load = false
          console.log(err);
          // this.msg("ERRO AO BUSCAR", err.response.data, "danger")
          this.displayTable = true;
          this.tableLoading = false;
        });



        //----------------------

      // api.get('webadmin/solicitacoes')
      //   .then(res => {
      //     // console.log('zzzzzzzzzzzzzzzzz',res.data);
      //     this.items = res.data;
      //   })
      //   .catch(err => console.log(err))

    },

    filtroCliente() {
      if (this.buscaCliente.length > 2) {
        this.getFiltroSolicitacoes("cliente", this.buscaCliente.toUpperCase());
      }
    },
    filtroUnidade() {
      if (this.buscaUnidade.length > 2) {
        this.getFiltroSolicitacoes("unidade", this.buscaUnidade.toUpperCase());
      }
    },
    filtroSetor() {
      if (this.buscaSetor.length > 2) {
        this.getFiltroSolicitacoes("setor", this.buscaSetor.toUpperCase());
      }
    },
    filtroGestor() {
      if (this.buscaGestor.length > 2) {
        this.getFiltroSolicitacoes("gestor", this.buscaGestor.toUpperCase());
      }
    },

    getFiltroSolicitacoes() {
      this.items = []
      this.load = true

      const camposParaFiltro = [{ campo: 'nenhum', valor: '' }]
      if (this.buscaCliente.length > 0) camposParaFiltro.push({ campo: 'cliente', valor: this.buscaCliente })  
      if (this.buscaUnidade.length > 0) camposParaFiltro.push({ campo: 'unidade', valor: this.buscaUnidade })  
      if (this.buscaSetor.length > 0) camposParaFiltro.push({ campo: 'setor', valor: this.buscaSetor }) 
      if (this.buscaGestor.length > 0) camposParaFiltro.push({ campo: 'gestor', valor: this.buscaGestor })  

      api
        .get(`webadmin/solicitacoes/filtro/${camposParaFiltro}/${this.status}/${this.buscaDataMin}/${this.buscaDataMax}`)
        .then(res => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false
          this.resultadoFiltro = '- '+this.items.length+' encontrados'
        })
        .catch(err => {
          this.load = false
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });
    },



    changeManage(socilitacao) {

      // console.log(socilitacao)
      localStorage.setItem('solicitacao', JSON.stringify(socilitacao))
      this.$router.push('/editarGestor')

    },

    toEscala(socilitacao) {

      // console.log('socilitacao', socilitacao)
      localStorage.setItem('solicitacao', JSON.stringify(socilitacao))
      this.$router.push('/solicitacoes/verEscala')

    }

  },

  computed: {
    searchParams() {
      const params = buildParamsRouteQuery(this.$route.query);
      params.append("page", this.page - 1);
      params.append("limit", this.limit);

      // this.currentFilter
      if (this.currentFilter.dtRange) {
        const dtRange = this.currentFilter.dtRange.split('até');
        const dataIni = dayjs(dtRange[0]).format('YYYY-MM-DD')
        params.append('data_ini', dataIni);

        if (dtRange[1]) {
          const dataFim = dayjs(dtRange[1]).format('YYYY-MM-DD')
          params.append('data_fim', dataFim);
        } else {
          params.append('data_fim', dataIni);
        }
      }
      // if (this.currentFilter?.dtRange[1]) {
      // }

      if (this.sortField.field) {
        params.append(`sort_${this.sortField.field}`, this.sortField.type);
      }

      return params;
    },

    resultadoBusca: function () {
      if (this.buscaSolicitante.trim() == '' && this.buscaUnidade.trim() == '' && this.buscaSetor.trim() == ''
      && this.buscaGestor.trim() == '' && this.buscaStatus.trim() == '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return item.solicitante.toLowerCase().match(this.buscaSolicitante);
        })
            .filter((item) => {
              return item.unidade.toLowerCase().match(this.buscaUnidade);
            })
            .filter((item) => {
              return item.setor.toLowerCase().match(this.buscaSetor);
            })
            .filter((item) => {
              return item.gestor.toLowerCase().match(this.buscaGestor);
            })
            .filter((item) => {
              return item.status.toLowerCase().match(this.buscaStatus);
            })
            // .filter((item) => {
            //   return item.dataMin.toLowerCase().match(this.buscaDataMin);
            // })
            // .filter((item) => {
            //   return item.buscaDataMax.toLowerCase().match(this.buscaDataMax);
            // })
      }
    }

  },

  created() {
    const dtIni = dayjs().startOf('month').format('YYYY-MM-DD');
    const dtEnd = dayjs().endOf('month').format('YYYY-MM-DD');
    this.advancedFilters[this.advancedFilters.findIndex(val => val.field == 'dtRange')].value = `${dtIni} até ${dtEnd}`;
    this.advancedFilters[this.advancedFilters.findIndex(val => val.field == 'status')].value = `solicitado`;
    this.currentFilter = {
      dtRange: `${dtIni} até ${dtEnd}`,
      status: 'solicitado'
    }
    setRouteQuery(this.currentFilter, this.$route.query);
    this.setInitialTableFilters();

  }

}
</script>


<style scoped>
h2 {
  color: brown;
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}

.contorno {
  border: solid #f8f9fa;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.tituloCoop {
  width: 100%;
  float: left !important;
  margin-top: 10px;
  margin-bottom: 25px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -33px;
    margin-left: -33px;
}

@media screen and (max-width: 480px) {
  .jumbotron {
    padding: 1%;
    margin-bottom: 2rem;
    background-color: #e9ecef15;
    border-radius: 0.3rem;
  }
}

@media (min-width: 576px){
.jumbotron {
  margin-top: 2rem;
    padding: 2rem 3rem;
}
}

.table-striped tr:hover {
  background-color: #dfdede;
}


</style>


